import React, { useState } from 'react'
import Link from 'gatsby-link'
import { globalHistory as history } from '@reach/router'
import { useMediaQuery } from 'react-responsive'

import { locationClassName } from './../helpers/History.helper'
import AppContext from '../context/AppContext'

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isDesktop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  })
  const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 1224px)',
  })
  const nameDOM = (
    <li className="edrig__header_name">
      <Link to="/">
        <span>Edouard<br />Rigaudiere</span>
        <small>film composer</small>
      </Link>
    </li>
  )

  const linksDOM = (
    <>
      <AppContext.Consumer>
        {ctx => (
          <>
            <li className={ locationClassName('music') }><Link to="/music/">Music</Link></li>
            <li className={ locationClassName('about') }><Link to="/about/">About/Contact</Link></li>
          </>
        )}
      </AppContext.Consumer>
    </>
  )

  return (
      <header className="edrig__header">
        <nav className="nav">
          <ul className="edrig__header_list">
            {nameDOM}
            {linksDOM}
          </ul>
        </nav>
        <nav className="nav-mobile">
          <div className="edrig__header_burgermenu">
            {isMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.213"
                height="21.213"
                viewBox="0 0 21.213 21.213"
                onClick={() => setIsMenuOpen(false)}
                onKeyUp={() => setIsMenuOpen(false)}
              >
                <title>Close Menu</title>
                <g
                  id="Group_3"
                  data-name="Group 3"
                  transform="translate(-332.393 -16.893)"
                >
                  <line
                    id="Line_12"
                    data-name="Line 12"
                    x2="29"
                    transform="translate(332.747 37.753) rotate(-45)"
                    fill="none"
                    stroke="#707070"
                    stroke-width="1"
                  />
                  <line
                    id="Line_13"
                    data-name="Line 13"
                    x2="29"
                    transform="translate(332.747 17.247) rotate(45)"
                    fill="none"
                    stroke="#707070"
                    stroke-width="1"
                  />
                </g>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="19"
                viewBox="0 0 29 19"
                onClick={() => setIsMenuOpen(true)}
                onKeyUp={() => setIsMenuOpen(true)}
              >
                <title>Menu</title>
                <g id="Menu" transform="translate(-328.5 -18)">
                  <line
                    id="Line_9"
                    data-name="Line 9"
                    x2="29"
                    transform="translate(328.5 18.5)"
                    fill="none"
                    stroke="#707070"
                    stroke-width="1"
                  />
                  <line
                    id="Line_10"
                    data-name="Line 10"
                    x2="29"
                    transform="translate(328.5 27.5)"
                    fill="none"
                    stroke="#707070"
                    stroke-width="1"
                  />
                  <line
                    id="Line_11"
                    data-name="Line 11"
                    x2="29"
                    transform="translate(328.5 36.5)"
                    fill="none"
                    stroke="#707070"
                    stroke-width="1"
                  />
                </g>
              </svg>
            )}
          </div>
          <ul className={`edrig__header_list ${isMenuOpen ? 'open' : ''}`}>
            {isMenuOpen ? linksDOM : nameDOM}
          </ul>
        </nav>
      </header>
    )
}
