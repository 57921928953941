import * as React from 'react'
import { locationClassName } from './../helpers/History.helper'

export function Footer() {
  return (
    <footer className={`edrig__footer ${locationClassName('/work', 'transparent')}`}>
      <nav className="edrig__footer_list">
        <a href="https://www.imdb.com/name/nm8251986/" target="_blank" rel="noopener noreferrer">
          <img src="https://static.cnginr.pw/ed-rig-com/images/icons/imdb.png" alt="IMDb Logo" />
        </a>
        <a href="https://www.facebook.com/ed.rig" target="_blank" rel="noopener noreferrer">
          <img src="https://static.cnginr.pw/ed-rig-com/images/icons/facebook.png" alt="Facebook Logo" />
        </a>
        <a href="https://www.instagram.com/ed_rig/" target="_blank" rel="noopener noreferrer">
          <img src="https://static.cnginr.pw/ed-rig-com/images/icons/instagram.png" alt="Instagram Logo" />
        </a>
        <a href="https://open.spotify.com/artist/11CZSOKUIB00WbTolRYpbF?si=hCMtRFMqR0qlpF-eBpGsmw" target="_blank" rel="noopener noreferrer">
          <img src="https://static.cnginr.pw/ed-rig-com/images/icons/spotify.png" alt="Spotify Logo" />
        </a>
        <a href="https://soundcloud.com/ed-rig" target="_blank" rel="noopener noreferrer">
          <img src="https://static.cnginr.pw/ed-rig-com/images/icons/soundcloud.png" alt="SoundCloud Logo" />
        </a>
      </nav>
    </footer>
  )
}
