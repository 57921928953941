import React from 'react'
import { WorkElement } from './../types/WorkElement'

const defaultState: AppContextState = {
  currentWork: undefined,
  setCurrentWork: (_w: WorkElement | undefined) => {},
}

const AppContext = React.createContext(defaultState)

class AppProvider extends React.Component<{}, AppContextState> {
  constructor(props: {}) {
    super(props)
    this.state = defaultState
  }

  public render() {
    const { children } = this.props
    const { currentWork } = this.state

    return (
      <AppContext.Provider
        value={{
          currentWork,
          setCurrentWork: this.setCurrentWork,
        }}>
        {children}
      </AppContext.Provider>
    )
  }

  private setCurrentWork = (currentWork: WorkElement | undefined) => {
    this.setState({ currentWork })
  }
}

type AppContextState = {
  currentWork: WorkElement | undefined
  setCurrentWork: (_w: WorkElement | undefined) => void
}

export default AppContext
export { AppProvider }
