import { globalHistory as history } from '@reach/router'

/**
 * Function to add a classname based on location history
 * @param  path      {string} path of the targetted page
 * @param  className {string} className to use
 * @return           {string}
 */
export function locationClassName(path: string, className: string = 'active'): string {
  const { location } = history

  return location.pathname.includes(path) ?
    className :
    ''
}
