import React from 'react'
import { Helmet } from 'react-helmet'

import { Header } from './../components/Header'
import { Footer } from './../components/Footer'
import { AppProvider } from '../context/AppContext'

export const GenericLayout: React.FunctionComponent<GenericLayoutProps> = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta property="og:description" content="Edouard Rigaudière is a Paris based film composer and musician." />
        <meta property="og:image" content="https://ed-rig.com/images/ed-rig__og.jpg" />
        <meta property="og:url" content="https://ed-rig.com" />

        <meta name="twitter:description" content="Edouard Rigaudière is a Paris based film composer and musician." />
        <meta name="twitter:image:src" content="https://ed-rig.com/images/ed-rig__og.jpg" />
      </Helmet>
      <AppProvider>
        <Header />
        {children}
        <Footer />
      </AppProvider>
    </>
  )
}

type GenericLayoutProps = {}
